#cloud {
    stroke: #bebebe;
}

#snow1, #snow3 {
    fill: #fc9b0b;
    animation: snow 2s infinite linear;
    animation-delay: 1s;
}

#snow2, #snow4 {
    fill: #80ade7;
    animation: snow 2s infinite linear;
    animation-delay: 2s;
}


@keyframes snow {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
